<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getData">
                <i class="fas fa-sync-alt"></i>
            </b-button>
            <b-row>
                <b-col md="4">
                    <base-input type="text" :label="$t('general.search_area')" :placeholder="$t('general.search_area')" v-model="filter.text" @input="searchTimeOut">
                    </base-input>
                </b-col>
                <b-col md="3">
                    <v-date-picker v-model="range" :locale="userLang" mode="dates" :masks="masks" is-range @input="setDates">
                        <template v-slot="{ inputValue, inputEvents, isDragging }">
                            <b-row>
                                <b-col md="6">
                                    <label class="form-control-label">{{$t('availability.start_date')}}</label>
                                    <input class="form-control" readonly :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                                </b-col>
                                <b-col md="6">
                                    <label class="form-control-label">{{$t('availability.end_date')}}</label>
                                    <input class="form-control" readonly :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                                </b-col>
                            </b-row>
                        </template>
                    </v-date-picker>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('availability.type')">
                        <el-select v-model="filter.type" filterable="" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option :label="$t('availability.online')" value="online"></el-option>
                            <el-option :label="$t('availability.normal')" value="normal"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.currency')">
                        <el-select v-model="filter.currency" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in currencyList" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <base-input :label="$t('payment.appointment_status')">
                    <el-select v-model="filter.appointmentStatus" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                        <el-option v-for="(item, key) in ['completed','waiting','canceled']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                    </el-select>
                </base-input>
                <b-col md="2">
                    <base-input :label="$t('general.payment_status')">
                        <el-select v-model="filter.paymentStatus" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in ['completed','waiting','canceled']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.payment_method')">
                        <el-select v-model="filter.paymentMethod" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in ['creditcard','banktransfer']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.earn_status')">
                        <el-select v-model="filter.earnStatus" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in ['completed','waiting']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body v-if="payments" class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="payments" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')">
                <el-table-column :label="$t('general.appointment_id')" min-width="280">
                    <template v-slot="{row}">
                        <small>{{row.appointment[0].uuid}}</small>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.payment_id')" prop="doctor" min-width="260" v-if="userType=='admin'">
                    <template v-slot="{row}">
                        <div v-if="row.paymentMethod=='creditcard'&&row.transaction[0]!=undefined">
                            <small>{{row.transaction[0].merchant_oid}}</small>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('payment.appointment_status')" min-width="200">
                    <template v-slot="{row}">
                        <b-badge :variant="row.appointment[0].appointment_status=='completed'?'success':(row.appointment[0].appointment_status=='waiting'?'primary':'danger')">
                            <template v-if="row.appointment[0].appointment_status=='completed'">{{$t('general.completed')}}</template>
                            <template v-if="row.appointment[0].appointment_status=='waiting'">{{$t('general.waiting')}}</template>
                            <template v-if="row.appointment[0].appointment_status=='canceled'">{{$t('general.canceled')}}</template>
                        </b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.payment_status')" min-width="180">
                    <template v-slot="{row}">
                        <b-badge :variant="row.paymentStatus=='completed'?'success':(row.paymentStatus=='waiting'?'primary':'danger')">
                            <template v-if="row.paymentStatus=='completed'">{{$t('general.completed')}}</template>
                            <template v-if="row.paymentStatus=='waiting'">{{$t('general.waiting')}}</template>
                            <template v-if="row.paymentStatus=='canceled'">{{$t('general.canceled')}}</template>
                        </b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.doctor')" prop="doctor" min-width="240" v-if="userType!='doctor'">
                    <template v-slot="{row}">
                        {{row.doctor[0].title}} {{row.doctor[0].name}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('profile.identity')" prop="identity" min-width="160">
                    <template v-slot="{row}">
                        <span v-if="!row.patient[0].nontc">{{row.patient[0].identity}}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.patient')" prop="patient" min-width="200">
                    <template v-slot="{row}">
                        {{row.patient[0].name}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.appointment')" prop="doctor" min-width="280">
                    <template v-slot="{row}">
                        <b-badge variant="primary" class="mr-1">{{$t('availability.'+row.availability[0].type)}}</b-badge>
                        <b-badge variant="primary" class="mr-1">{{row.availability[0].date | getIntDate}}</b-badge>
                        <b-badge variant="primary" class="mr-1">{{row.availability[0].time | getIntTime}}</b-badge>
                        <b-badge variant="primary" class="mr-1">{{row.availability[0].min}} {{$t('general.min')}}</b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.payment_method')" prop="paymentMethod" min-width="160">
                    <template v-slot="{row}">
                        <b-badge :variant="row.paymentMethod=='creditcard'?'success':'primary'">
                            {{$t('general.'+row.paymentMethod)}}
                        </b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.price')" prop="price" min-width="160">
                    <template v-slot="{row}">
                        {{row.amount}} {{row.currency | getCurrency}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.commission')" prop="comission" min-width="160">
                    <template v-slot="{row}">
                        {{row.commission}} {{row.currency | getCurrency}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.earn')" prop="earn" min-width="160">
                    <template v-slot="{row}">
                        {{row.amount - row.commission}} {{row.currency | getCurrency}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.earn_status')" prop="earn" min-width="180">
                    <template v-slot="{row}">
                        <b-badge :variant="row.earn?'success':'primary'">
                            <template v-if="row.earn">{{$t('general.completed')}}</template>
                            <template v-else>{{$t('general.waiting')}}</template>
                        </b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('payment.date')" prop="updatedAt" min-width="200">
                    <template v-slot="{row}">
                        {{row.updatedAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="170" v-if="userType=='admin'">
                    <template slot-scope="scope">
                        <base-button type="success" size="sm" @click="approvedEarn(scope.row.uuid)" v-if="scope.row.appointment[0].appointment_status=='completed'&&scope.row.paymentStatus=='completed'&&!scope.row.earn">
                            <small>{{$t('general.approved_earn')}}</small>
                        </base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
            <b-row class="mt-4" v-if="footer.currency.length>0">
                <b-col md="6">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead class="thead-light">
                                <tr>
                                    <th>{{$t('general.currency')}}</th>
                                    <th>{{$t('general.total_payment')}}</th>
                                    <th>{{$t('general.total_commission')}}</th>
                                    <th>{{$t('general.total_earn')}}</th>
                                    <th>{{$t('general.payed_earn')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in footer.currency" :key="item">
                                    <td>{{item.toUpperCase()}}</td>
                                    <td class="text-right">{{footer.amount[item]}} {{item | getCurrency}}</td>
                                    <td class="text-right">{{footer.commission[item]}} {{item | getCurrency}}</td>
                                    <td class="text-right">{{footer.earn[item]}} {{item | getCurrency}}</td>
                                    <td class="text-right">{{footer.payed[item]}} {{item | getCurrency}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { PAYMENT_REQUEST, PAYMENT_UPDATE } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;

export default {
    computed: {
        ...mapState(['userLang']),
        ...mapGetters({ payments: 'getPayments', total: 'getPaymentTotal', pages: 'getPaymentPages', footer: 'getPaymentFooter', userType: 'getUserType' })
    },
    data() {
        return {
            loading: false,
            masks: {
                input: 'DD.MM.YYYY'
            },
            currencyList: ['try', 'usd', 'eur', 'gbp'],
            range: {
                start: moment()
                    .clone()
                    .startOf('month')
                    .toDate(),
                end: moment()
                    .clone()
                    .startOf('month')
                    .add(1, 'month')
                    .subtract(1, 'day')
                    .toDate()
            },
            filter: {
                text: '',
                earnStatus: '',
                appointmentStatus: '',
                paymentStatus: '',
                paymentMethod: '',
                currency: 'try',
                doctor: '',
                type: '',
                start: moment()
                    .clone()
                    .startOf('month')
                    .format('YYYYMMDD'),
                end: moment()
                    .clone()
                    .startOf('month')
                    .add(1, 'month')
                    .subtract(1, 'day')
                    .format('YYYYMMDD')
            },
            pagination: {
                limit: 10,
                page: 1
            }
        };
    },
    methods: {
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData();
            }, 800);
        },
        changePage(val) {
            self.pagination.page = val;
            self.getData();
        },
        setDates() {
            _.set(self.filter, 'start', moment(self.range.start).format('YYYYMMDD'));
            _.set(self.filter, 'end', moment(self.range.end).format('YYYYMMDD'));

            self.getData();
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(PAYMENT_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        approvedEarn(uuid) {
            console.log(uuid);
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.earn_approve_confirm'),
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#2dce89',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PAYMENT_UPDATE, { uuid, payment: { earn: 'completed' } })
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        }
    },
    created() {
        self = this;
        self.getData();
    }
};
</script>
